<template>
  <div
    ref="markdown-text"
    class="v-markdown v-markdown-result mt-4 resized"
    :class="{ 'small': $vuetify.breakpoint.smAndDown  }"
    :style="`color:${color}`"
    v-html="compiledMarkdown"
  ></div>
</template>

<script>
import renderer from "./new-renderer.js";
import marked from "marked";
import "./v-markdown.css";

export default {
  name: "markdown-view",
  props: {
    value: String,
    color: { type: String, default: "#999" },
    video: Boolean,
  },
  watch: {
    compiledMarkdown() {
      this.internalLinksToRouter();
    },
  },

  computed: {
    compiledMarkdown: function () {
      if (!this.value) return "";
      return marked(this.value, { sanitize: true });
    },
  },

  methods: {
    openEdit() {
      this.$emit("click:edit");
    },

    /**
     * Ativa a exibição do vídeo ou a exibição da miniutatura
     * do vídeo
     */
    chooseRenderer() {
      marked.use({ renderer: renderer({ video: this.video }) });
    },

    /**
     * Adiciona o evento aos links internos
     */
    async internalLinksToRouter() {
      await this.$nextTick();
      const el = this.$refs["markdown-text"];

      const links = el.querySelectorAll(".local-url.v-markdown-url");

      for (var link of links) {
        const url = link.getAttribute("href");

        link.onclick = (e) => {
          e.preventDefault();
          this.$router.push(url);
        };
      }
    },
  },

  mounted() {
    this.chooseRenderer();
  },
};
</script>

<style scoped>
</style>