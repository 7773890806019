<!--

  <SectionHeader
    title="Serviços"
    subtitle="Serviços vinculados a esta obra"
    :actions="[
      {
        // Ícone que será exibido
        icon: 'mdi-plus',
        // Exibe um texto de ajuda
        label: 'Clique para isso e aquilo',
        // 
        eventName: 'click:add',
        // Todos os parâmetros a seguir serão passados diretamente para o botão
        to: '/services/add',
        target: '_blank',
        color:"red"
      }
    ]"

    // O "click:add" é o valor em "eventName"
    @click:add="onClickAdd"
  >
    
  </SectionHeader>

-->
<template>
  <v-row class="ma-0">
    <h2 class="title font-weight-light" v-bind="$attrs">
      {{ title }}
      <p v-if="subtitle" class="ma-0 caption">{{ subtitle }}</p>
    </h2>

    <v-spacer></v-spacer>
    <div v-if="actions && actions.length" class="ma-0 text-no-wrap">
      <span v-for="item in actionsList" :key="item.icon">
        <!-- Caso tenha tooltip -->
        <v-tooltip v-if="item.label" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="{ ...item.attrs, ...attrs }"
              v-on="on"
              @click="handleClick(item)"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.label }}</span>
        </v-tooltip>

        <!-- Caso não tenha tooltip -->
        <v-btn v-else icon @click="handleClick(item)" v-bind="item.attrs">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </span>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "section-header",

  props: {
    title: String,
    subtitle: String,
    actions: { type: [Array, Boolean], default: false }
  },

  computed: {
    actionsList() {
      if (this.actions) {
        return this.actions.map(action => {
          // Event key é o nome do evento que será chamado caso seja necessário clicar nele
          const { icon, label = null, eventName, ...attrs } = action;
          return { icon, label, eventName, attrs };
        });
      }

      return [];
    }
  },

  methods: {
    handleClick(item) {
      console.log("dentro do header", item);

      this.$emit(`click:${item.key}`, item);
      if (item.eventName) {
        this.$emit(item.eventName);
      }
    }
  }
};
</script>

<style>
</style>
