
export default function renderer(options = {}) {



  /**
   * Padrões de URLs.
   * Lista os padrões de urls encontradas no texto.
   * 
   * Padrão:
   *  {
   *    pattern {RegExp}    : Expressão regular que localiza a url no texto
   *    callback {Function} : Função que receberá a url e retornará um HTML
   *                          que será exibido no texto. A função  receberá
   *                          os parâmetros href e text, sendo, respectiva-
   *                          mente: A url do link e o texto  informado  no
   *                          texto do markdown.
   *  } 
   * 
   * Para adicionar um novo serviço é necessário apenas criar uma entrada na lista
   * abaixo.
   * 
   * O link não encontrado na lista abaixo será retornado pela função external() 
   * logo abaixo.
   */
  const patterns = {
    // Links iniciados com "/"
    local: {
      pattern: /^\//,
      callback: local
    },

    // Links do Youtube
    youtube: {
      pattern: /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
      callback: youtube
    }
  }


  /**
   * Função que retorna links locais.
   * 
   * @param href {String}: Url
   * @param text {String}: Texto
   */
  function local(href, text) {
    return `<a class="local-url v-markdown-url" href="${href}">${text}</a>`
  }

  /**
   * Função que retorna links externos.
   *
   * @param href {String}: Url
   * @param text {String}: Texto
   */
  function external(href, text) {
    return `<a class="external-url v-markdown-url" target="_blank" href="${href}">${text}</a>`
  }



  /**
   * Função que retorna links do youtube como vídeo ou imagem.
   * Depende das configurações passadas para o renderizador lá
   * no componente.
   *
   * @param href {String}: Url
   * @param text {String}: Texto
   */
  function youtube(href, text) {
    const youtubeId = (function (href) {
      var youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var youtubeMatch = href.match(youtubeRegex);
      if (youtubeMatch && youtubeMatch[7] && youtubeMatch[7].length == 11) {
        return youtubeMatch[7];
      } else {
        return undefined;
      }
    })(href)

    if (youtubeId) {
      if (options.video) {
        return `<iframe height="100%" width="100%" class="marked marked-video marked-video-youtube" 
          id="${youtubeId}" frameborder="0" allowfullscreen
          src="https://www.youtube.com/embed/${youtubeId}?rel=0&controls=1&hd=1&showinfo=0&enablejsapi=1"
        ></iframe>`;
      } else {
        return `<a href="https://youtu.be/${youtubeId}"><img style="width:100%;max-width:800px" src="http://i3.ytimg.com/vi/${youtubeId}/maxresdefault.jpg" /></a>`;
      }
    } else {
      return null;
    }
  }

  /**
   * Função que retorna links do vimeo como vídeo ou imagem.
   * Depende das configurações passadas para o renderizador lá
   * no componente.
   *
   * @param href {String}: Url
   * @param text {String}: Texto
   */
  function vimeo(href, text) {

  }


  /**
   * Avalia o link para ver se acha alguma compatibilidade com algum
   * dos serviços suportados no momento.
   */
  function matcher(href, text) {
    const service = Object.keys(patterns).find(key => patterns[key].pattern.test(href))
    return patterns[service] || null;
  }

  /**
   * Verifica os links para avaliar a melhor exibição e os renderiza de acordo.
   */
  function linkRenderer(href, text) {
    // Avalia a url passada para ver se encaixa-se em algum 
    // serviço de vídeos, embed etc. 
    const matched = matcher(href, text);

    // Se for encontrado algum serviço compatível com a url informada, 
    // chame o seu callback específico.
    if (matched) {
      return matched.callback(href, text)
    }

    // Caso tenha chegado até aqui, trata-se apenas de um link simples 
    // para conteúdo externo. Renderize-o com a class external-link
    return external(href, text)
  }

  /**
   * Retorna o renderizador no formato perfeito para ser exibido.
   * LinkRenderer avalia se a url passada pertence a algum serviço específico
   * e já retorna formatado caso pertença, de acordo com as configurações.
   * Caso não ache nenhum serviço, retorne um link comum.
   */
  return {
    link(href, title, text) {
      return linkRenderer(href, text)
    }
  }
}